/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
const slick = require('slick-carousel');

// ==========================================//
// Slick Slider with SCSS Float Grid
// ==========================================//

// const $window = $(window);

const createBreakpoints = function($sliderElement, inheritSlidesToScroll = true) {
	// Apply breakpoint classes to .js-slider
	// Works using class names `bp-up-i` so `sm-up-4` outputs 4 slides to be shown on small screens upwards
	let breakpoints = {init: 0,xxs: 480,xs: 540,sm: 640,md: 768,xmd: 968,lg: 1024,xl: 1280,xxl: 1440};
	let responsiveBreakpoints = [];
	let classBreakpoints = $sliderElement[0].className.split(' ');
	let slidesToScroll;

	classBreakpoints.forEach(function (value) {
		if(value.indexOf(`-up-`) > -1){
			let breakpoint = value.split('-')[0];
			let slides = parseInt(value.split('-').pop().trim());
			if (inheritSlidesToScroll) {
				slidesToScroll = slides;
			}else {
				slidesToScroll = 1;
			}
			responsiveBreakpoints.push({breakpoint: breakpoints[breakpoint],settings: {slidesToShow: slides,slidesToScroll: slidesToScroll}});
		}
	});

	return responsiveBreakpoints;
}

const makeSlider = function(selector, options, inheritSlidesToScroll = true) {
	let $el = $(selector);

	$el.each(function(){
		let $this = $(this);
		options.responsive = createBreakpoints($this, inheritSlidesToScroll);
		options.mobileFirst = true;
		$this.slick(options);
	});
}

makeSlider('.js-slider--banner', {
	// Options
	infinite: false,
	dots: true,
	arrows: false,
	fade: true,
	// autoplay: true,
	// autoplaySpeed: 5000,
	// pauseOnHover: true,
	// prevArrow:`<button class="slick-left"><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path class="pageTheme--primary stroke" d="M.84 1.024l6.985 6.955-6.984 6.955" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
	// nextArrow:`<button class="slick-right pull-right"><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path class="pageTheme--primary stroke" d="M.84 1.024l6.985 6.955-6.984 6.955" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
}, false);

makeSlider('.js-slider--threeColumnBlock', {
	// Options
	infinite: false,
	dots: false,
	arrows: true,
	// autoplay: true,
	// autoplaySpeed: 5000,
	// pauseOnHover: true,
	prevArrow:`<button class="slick-left"><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path class="pageTheme--primary stroke" d="M.84 1.024l6.985 6.955-6.984 6.955" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
	nextArrow:`<button class="slick-right pull-right"><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path class="pageTheme--primary stroke" d="M.84 1.024l6.985 6.955-6.984 6.955" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
}, false);