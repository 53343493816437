/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import throttle from 'lodash/throttle';
import {TweenMax} from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';

const $window = $(window);
const $nav = $('.js-nav');
const controller = new ScrollMagic.Controller();

// ==========================================//
// Parallax
// ==========================================//

// (function ($) {

// 	$.fn.inView = function () {
// 		if ($window.scrollTop() + $window.height() >= $(this).offset().top && $window.scrollTop() <= $(this).offset().top + $(this).height()) {
// 			return true;
// 		}
// 		return false;
// 	};

// 	$.fn.yPercent = function () {
// 		let y = ($(this).offset().top - $window.scrollTop()) / $window.height() * 100; 
// 		// console.log(y);
// 		return y;
// 	};

	
// 	$.fn.parallax = function (options) {

// 		var $this = $(this);
// 		var $target = $this.find('.js-parallax-target');
// 		var settings = $.extend({
// 			scale: 0.2,
// 			duration: 0.4,
// 			bp: 0,
// 			buffer: 100
// 		}, options);
// 		var transition = `transform ${settings.duration}s ease-out`;
// 		setTimeout(function(){
// 			$target.css({'-webkit-transition': transition,'-moz-transition': transition,'-o-transition': transition,'-ms-transition': transition,'transition': transition,});
// 		}, 50);

// 		var changePos = function(yPercent){
// 			$target.css({
// 				'transform': `translateY(${(yPercent) * -settings.scale / 5}vh)`
// 			})
// 		}

// 		$window.on('scroll', throttle(function () {

// 			if ($window.width() >= settings.bp) {
// 				if ($this.inView()) {
// 					changePos($this.yPercent() - 33.333);
// 				}
// 			}else {
// 				$target.css({'transform': 'translateY(0)'});
// 			}

// 		}, settings.buffer))

// 		if ($window.width() >= settings.bp) {
// 			if ($this.inView()) {
// 				changePos($this.yPercent() - 33.333);
// 			}else {
// 				changePos(66.666);
// 			}
// 		}

// 	};

// }($));

// $('.js-parallax').each(function () {
// 	let $this = $(this);
// 	let settings = {
// 		scale: 0.2,
// 		bp: 0,
// 	};

// 	if ($this.data('scale') != undefined) {
// 		settings.scale = $this.data('scale');
// 	}

// 	if ($this.data('bp') != undefined) {
// 		settings.bp = parseInt($this.data('bp'));
// 	}

// 	$this.parallax({ scale: settings.scale, duration: 0.2, bp: settings.bp });
// });

// ==========================================//
// Fade In
// ==========================================//

function fadeUp(element, duration = 1.4) {

	$(element).each(function () {
		let $element = $(this);
		let fadeUp = new TimelineMax({});
		let position = $element.css('position');


		if (position == 'static') {
			$element.css('position', 'relative');
		}

		fadeUp.fromTo($element, duration, { ease: Power2.easeOut, autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0 });

		let fadeUpScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 1, reverse: false })
			.setTween(fadeUp)
			// .addIndicators('tween', 'element')
			.addTo(controller);
	})
}

fadeUp('.js-fade-up');

function fadeIn(element, duration = 1.4) {

	$(element).each(function () {
		let $element = $(this);
		let fadeIn = new TimelineMax({});

		fadeIn.fromTo($element, duration, { ease: Power2.easeOut, autoAlpha: 0 }, { autoAlpha: 1 });


		let fadeInScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 1, reverse: false })
			.setTween(fadeIn)
			// .addIndicators('tween', 'element')
			.addTo(controller);
	})
}

fadeIn('.js-fade');

function staggerIn($parent, $target, duration = 1.4) {
	let staggerIn = new TimelineMax();

	staggerIn.staggerFromTo($target, duration, { autoAlpha: 0, y: 20, ease: Power2.easeOut }, { autoAlpha: 1, y: 0 }, 0.2, 0.2);

	let staggerInScene = new ScrollMagic.Scene({ triggerElement: $parent[0], triggerHook: 1, reverse: false })
		.setTween(staggerIn)
		// .addIndicators('tween', 'element')
		.addTo(controller);

}

$('.js-stagger-in').each(function () {
	let $this = $(this);
	staggerIn($this, $this.find('.js-stagger-in-watch:not(.slick-cloned)'));
})

$('.js-stagger-in-all').each(function () {
	let $this = $(this);
	staggerIn($this, $this.find('>*:not(.slick-cloned)'));
})

$window.on('scroll', throttle(function(){
	($window.scrollTop() >= 100) ? $nav.addClass('fill') : $nav.removeClass('fill');
}, 500));