/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $ from 'jquery';
import Noty from "noty";
import swal from 'sweetalert2';

let $body = $('body');

const changeFormLayout = () => {

    let type = $('input[name=CustomerType]:checked').val();
    if (type === 'NewCustomer') {
        $('.display-existing-customer').hide();
        $('.display-new-customer').show();
    } else {
        $('.display-new-customer').hide();
        $('.display-existing-customer').show();
    }
};

const $paymentForm = $('#PaymentForm_PaymentForm');

if ($paymentForm.length) {
    $paymentForm.parsley({
        classHandler: function (el) {
            return el.$element.closest('.input-wrap');
        }
    })
    //.on('form:submit', () => {
    //$.ajax({
    //    url : $paymentForm.attr('action'),
    //    type: 'POST',
    //    data: $paymentForm.serialize()
    //}).done(function (response) {
    //    let data = JSON.parse(response);
    //    new Noty({ type: 'success', text: `${data.message}` }).show();
    //    $paymentForm[0].reset();
    //}).fail(function (response) {
    //    let data = JSON.parse(response);
    //    new Noty({ type: 'error', text: `${data.message}` }).show();
    //});
    //return false;
    //});
}


changeFormLayout();

$body.on('change', 'input[name=CustomerType]', function (e) {
    e.preventDefault();
    changeFormLayout();
});

/*------------------------------------------------------------------
Contact Form
 ------------------------------------------------------------------*/

const $onlineEnquiryForm = $('#OnlineEnquiryForm_OnlineEnquiryForm');

if ($onlineEnquiryForm.length) {
    $onlineEnquiryForm.parsley({
        classHandler: function (el) {
            return el.$element.closest('.input-wrap');
        }
    }).on('form:submit', () => {
        $.ajax({
            url: $onlineEnquiryForm.attr('action'),
            type: 'POST',
            data: $onlineEnquiryForm.serialize()
        }).done(function (response) {
            let data = JSON.parse(response);
            swal({
                type: 'success',
                title: 'Success!',
                html: data.message,
                confirmButtonText: 'OK',
            });

            let type = $('#OnlineEnquiryForm_OnlineEnquiryForm_NewEnquiryType').val();
            if (type.length < 1) {
                type = $('#OnlineEnquiryForm_OnlineEnquiryForm_EnquiryType').val();
            }
            window.dataLayer.push({
                'event': 'ga_event',
                'event_category': 'Enquiry Submission',
                'event_action': type,   // the category of enquiry they select
                'event_label': '(not set)'
            });

            $onlineEnquiryForm[0].reset();
        }).fail(function (response) {
            let data = JSON.parse(response);
            new Noty({ type: 'error', text: `${data.message}` }).show();

            swal({
                type: 'error',
                title: 'Whoops!',
                html: data.message,
                confirmButtonText: 'OK',
            });
        });
        return false;
    });
}

$body.on('click', '.js-change-form-choice', function (e) {
    e.preventDefault();
    let dataID = $(this).attr('data-id');
    console.log(`.showHide[data-id="${dataID}"]`);
    $('.showHide').hide();
    $('.js-change-form-choice').removeClass('selected');
    $('.showHide').find('input').prop('required', false);
    $('.showHide').find('input').removeAttr('required');
    $(this).addClass('selected');
    $(`.showHide[data-id="${dataID}"]`).show();
    $(`.showHide[data-id="${dataID}"]`).find('input').prop('required', true);
});

$('.showHide').hide();
$('.js-change-form-choice').removeClass('selected');
$('.showHide').find('input').prop('required', false);
$('.showHide').find('input').removeAttr('required');
$(`.navBar__item[data-id="1"]`).addClass('selected');
$(`.showHide[data-id="1"]`).show();
$(`.showHide[data-id="1"]`).find('input').prop('required', true);

Noty.overrideDefaults({
    layout: 'bottomRight',
    theme: 'metroui',
    timeout: 5000,
    animation: {
        open: 'animated bounceInRight',
        close: 'animated bounceOutRight'
    }
});
let $contactForm = $('#OnlineEnquiryFormBasic_OnlineEnquiryFormBasic')
if ($contactForm.length) {
    $contactForm.parsley({
        classHandler: function (el) {
            return el.$element.closest('.input-wrap');
        }
    })
        .on('form:submit', () => {
            $.ajax({
                url: $contactForm.attr('action'),
                type: 'POST',
                dataType: 'json',
                data: $contactForm.serialize()
            }).done(function (response) {
                if (response.status === 'success') {
                    // new Noty({ type: 'success', text: `${response.message}` }).show();
                    swal({
                        type: 'success',
                        title: 'Success!',
                        html: response.message,
                        confirmButtonText: 'OK',
                    });
                    $contactForm[0].reset();
                } else {
                    // new Noty({ type: 'success', text: `${response[0].message}` }).show();
                    swal({
                        type: 'warning',
                        title: 'Warning!',
                        html: response[0].message,
                        confirmButtonText: 'OK',
                    });
                    $contactForm[0].reset();
                }
            }).fail(function (response) {
                // new Noty({ type: 'error', text: `${response[0].message}` }).show();
                swal({
                    type: 'warning',
                    title: 'Warning!',
                    html: response[0].message,
                    confirmButtonText: 'OK',
                });
            });
            return false;
        });
}
