/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $               from 'jquery';
import _               from 'lodash';
import mapStyles       from './mapStyles';
import MarkerClusterer from './cluster';

window.$ = $;
/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $body      = $('body');
let brokers;
let createdMap = false;

let markers = [];
let map;
let markerClusterer;

let GoogleMapsLoader = require('google-maps');

GoogleMapsLoader.KEY = 'AIzaSyAONpBAgNB0aSdvhwyQOu22OeQPnYOVPYk';

GoogleMapsLoader.load(function (google) {
    brokers = window.locations;
    createMap();
    createFields(brokers);
});

let regions  = [];
let branches = [];
let people   = [];

function gup(name) {
    let url     = window.location.href;
    name        = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    let regexS  = "[\\?&]" + name + "=([^&#]*)";
    let regex   = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
}

function sortRegions(collection) {
    let regionOrderArray = [
        `Northland`,
        `Auckland`,
        `Waikato`,
        `Bay Of Plenty`,
        `Hawke's Bay`,
        `Taranaki`,
        `Manawatu-Wanganui`,
        `Wellington`,
        `Tasman`,
        `Marlborough`,
        `West Coast`,
        `Canterbury`,
        `Otago`,
        `Southland`,
    ];

    let indexObject = _.reduce(collection, function (result, currentObject) {
        result[currentObject] = currentObject;
        return result;
    }, {});
    return _.map(regionOrderArray, function (currentGUID) {
        return indexObject[currentGUID]
    });
}

function createFields(brokers) {
    _.each(brokers, (broker) => {
        regions.push(broker.region);
        branches.push(broker.title);
        _.each(broker.brokers, (person) => {
            people.push(person);
        });
    });
    regions  = _.uniq(regions);
    regions  = sortRegions(regions);
    branches = _.uniq(branches);

    _.each(regions, (region) => {
        $('.js-regions').append(`<option value="${region}">${region}</option>`);
    });

    $('.js-regions').select2({ width: '100%' });

    $('.js-branches').select2({ width: '100%', placeholder: 'Branch' });

    _.each(people, (person) => {
        $('.js-people').append(`<option value="${person.id}">${person.title}, ${person.job_title}, ${person.branch}</option>`);
    });

    $('.js-people').select2({ width: '100%', placeholder: 'Find a broker' });

    let person = gup('person');
    if (person && person.length) {
        $('.js-people').val(person).trigger('change');
    }

    let personWindow = window.active_broker;
    if (personWindow) {
        $('.js-people').val(personWindow).trigger('change');
    }

    let region = gup('region');
    if (region && region.length) {
        $('.js-regions').val(decodeURI(region)).trigger('change');
    }

    let branch = gup('branch');
    if (branch && branch.length) {
        let location = _.filter(brokers, (broker) => {
            return broker.title.toLowerCase() === decodeURI(branch).toLowerCase();
        });
        $('.js-regions').val(capitalise(decodeURI(location[0].region))).trigger('change');
        setTimeout(() => {
            $('.js-branches').val(capitalise(decodeURI(branch))).trigger('change');
        }, 200)
    }

    let branchWindow = window.active_branch;
    if (branchWindow) {
        let location = _.filter(brokers, (broker) => {
            return broker.id === branchWindow;
        });
        //console.log(branchWindow);
        //console.log(brokers);
        //console.log(location);
        //console.log(capitalise(decodeURI(location[0].region)));
        $('.js-regions').val(capitalise(decodeURI(location[0].region))).trigger('change');
        setTimeout(() => {
            //console.log(location[0].title);
            $('.js-branches').val(location[0].title).trigger('change');
        }, 200)
    }
}

function capitalise(str, force) {
    str = force ? str.toLowerCase() : str;
    return str.replace(/(\b)([a-zA-Z])/g,
        function (firstLetter) {
            return firstLetter.toUpperCase();
        });
}

$body.on('change', '.js-regions', function (e) {
    e.preventDefault();
    $('.js-infobox').removeClass('active');
    $('.brokers').hide();
    let value = $(this).val();
    if (value === '-1' || value === null) {
        $('.js-branches').val(null).prop('disabled', true).trigger('change');
        let bounds = new google.maps.LatLngBounds();
        _.each(markers, (marker) => {
            bounds.extend(new google.maps.LatLng(marker.details.lat, marker.details.lng));
        });
        map.fitBounds(bounds);
    } else {
        $('.js-branches').prop('disabled', false);
        $('.js-branches').empty();
        _.each(brokers, (broker) => {
            if (broker.region === value) {
                let newOption = new Option(broker.title, broker.title, false, false);
                $('.js-branches').append(newOption);
            }
        });
        $('.js-branches').val(null).trigger('change');

        let bounds = new google.maps.LatLngBounds();
        _.each(markers, (marker) => {
            if (marker.details.region === value) {
                bounds.extend(new google.maps.LatLng(marker.details.lat, marker.details.lng));
            }
        });
        map.fitBounds(bounds);
        setTimeout(() => map.setZoom(map.getZoom() - 2), 10);
    }
    populatePeople();
});

function populatePeople() {
    setTimeout(() => {
        $('.js-people').empty();
        if ($('.js-regions').val() === '-1' || $('.js-regions').val() === null) {
            _.each(people, (person) => {
                let newOption = new Option(`${person.title}, ${person.job_title}, ${person.branch}`, person.id, false, false);
                $('.js-people').append(newOption);
            });
        } else if ($('.js-branches').val() === '-1' || $('.js-branches').val() === null) {
            _.each(brokers, (broker) => {
                if (broker.region === $('.js-regions').val())
                    _.each(broker.brokers, (person) => {
                        if ($('.js-regions').val()) {
                            let newOption = new Option(`${person.title}, ${person.job_title}, ${person.branch}`, person.id, false, false);
                            $('.js-people').append(newOption);
                        }
                    });
            });
        } else {
            _.each(brokers, (broker) => {
                if (broker.title === $('.js-branches').val())
                    _.each(broker.brokers, (person) => {
                        if ($('.js-regions').val()) {
                            let newOption = new Option(`${person.title}, ${person.job_title}, ${person.branch}`, person.id, false, false);
                            $('.js-people').append(newOption);
                        }
                    });
            });
        }
        $('.js-people').val(null).trigger('change');
    }, 100)
}

$body.on('change', '.js-branches', function (e) {
    e.preventDefault();
    $('.brokers').hide();
    $('.js-infobox').removeClass('active');

    let counter = 0;
    let value   = $(this).val();
    if (value === '-1' || value === null) {

    } else {
        let bounds = new google.maps.LatLngBounds();
        _.each(markers, (marker) => {
            if (marker.details.title === value) {
                bounds.extend(new google.maps.LatLng(marker.details.lat, marker.details.lng));
                counter++;

                infoBox(marker.details);

            }
        });
        map.fitBounds(bounds);
        if (counter === 1) {
            setTimeout(() => map.setZoom(map.getZoom() - 4), 10);
        }
        window.dataLayer.push({
            'event'         : 'ga_event',
            'event_category': 'Find Broker Region',
            'event_action'  : `${$('.js-regions').val()}, ${value}`,
            'event_label'   : '(not set)'
        });
    }
    populatePeople();
});

function createMap() {
    let $map = $('.js-large-map');
    if ($map.length > 0 && !createdMap) {
        let bounds     = new google.maps.LatLngBounds();
        createdMap     = true;
        let mapOptions = {
            zoom            : 5,
            center          : new google.maps.LatLng(-41.4641287, 173.6844829),
            backgroundColor : 'hsla(0, 0%, 0%, 0)',
            disableDefaultUI: true,
        };

        map = new google.maps.Map($map[0], mapOptions);
        _.each(brokers, (location) => {
            if (location.latitude.length && location.longitude.length) {
                let people = [];

                _.each(location.brokers, (person) => people.push(person.id));

                let marker = new google.maps.Marker({
                    position: new google.maps.LatLng(location.latitude, location.longitude),
                    icon    : { url: 'themes/monument/dist/images/poi@2x.png', size: new google.maps.Size(20, 30), scaledSize: new google.maps.Size(20, 30) },
                    details : {
                        id            : location.id,
                        lat           : location.latitude,
                        lng           : location.longitude,
                        title         : location.title,
                        address       : location.address,
                        country       : location.country,
                        fax           : location.fax,
                        phone         : location.phone,
                        postal_address: location.postal_address,
                        email         : location.email,
                        website       : location.website,
                        region        : location.region,
                        people        : people
                    }
                });

                marker.addListener('click', function () {
                    infoBox(this.details);
                    map.panTo(this.getPosition());
                });

                markers.push(marker);

                bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));

            }
        });
        let clusterStyles = [
            { textSize: 10, textColor: 'white', url: 'themes/monument/dist/images/clusterer@2x.png', width: 30, height: 30 },
            { textSize: 10, textColor: 'white', url: 'themes/monument/dist/images/clusterer@2x.png', width: 30, height: 30 },
            { textSize: 10, textColor: 'white', url: 'themes/monument/dist/images/clusterer@2x.png', width: 30, height: 30 }
        ];

        markerClusterer = new MarkerClusterer(map, markers, { gridSize: 50, styles: clusterStyles, enableRetinaIcons: true });

        map.fitBounds(bounds);

    }
}

$('.js-zoom-in').on('click', function (e) {
    e.preventDefault();
    map.setZoom(map.getZoom() + 1);
});

$('.js-zoom-out').on('click', function (e) {
    e.preventDefault();
    map.setZoom(map.getZoom() - 1);
});

let modal;

function infoBoxTemplate(details) {
    let email  = (details.email) ? `<a href="mailto:${details.email}">${details.email}</a><br>` : ``;
    let phone  = (details.phone) ? `<a class="colour--text" href="tel:${details.phone}">P ${details.phone}</a><br>` : ``;
    let fax    = (details.fax) ? `<a class="colour--text" href="${details.fax}">F ${details.fax}</a><br>` : ``;
    let postal = (details.postal_address) ? `<br>Postal Address<br>${details.postal_address}` : ``;

    let additionalDetails = `<p class="colour--tertiary">${phone}</p>`;
    return `<div class="infoBox__inner wysiwyg">
                <a href="#" class="infoBox__inner__close [ js-close-infobox ]"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13 11.7L23.4 1.4 22 0 11.6 10.3 1.4 0 0 1.4l10.3 10.3L0 22l1.4 1.3L11.7 13 22 23.4l1.3-1.4" fill="#863aa7" fill-rule="evenodd"/></svg></a>
                <h6 class="colour--tertiary">${details.title}</h6>
                <p class="colour--tertiary">
               ${email}
                <br>${details.address}<br> ${details.country || ''}
                ${postal}
                </p>
                   ${(phone) ? `${additionalDetails}` : ``}
                <p class="link [ js-view-location-from-map ]" data-id="${details.id}">VIEW BROKERS</p>
            </div>`;
}

function infoBox(details) {
    let $infoBox = $('.js-infobox');
    $infoBox.html(infoBoxTemplate(details)).addClass('active');
}

$body.on('click', '.js-close-infobox', function (e) {
    e.preventDefault();
    $('.js-infobox').removeClass('active');
});

$body.on('click', '.js-view-location-from-map', function (e) {
    e.preventDefault();
    $('.brokers').show();
    $('.js-infobox').removeClass('active');
    $('html, body').animate({ scrollTop: $('.brokers__wrap').offset().top }, 800);
    $(`[data-location-id="${$(this).attr('data-id')}"]`).find('.js-get-panel').trigger('click');
    renderBrokers(parseInt($(this).attr('data-id')));
});

let $brokersWrap = $('.js-brokers-wrap');

function renderBrokers(id) {
    $brokersWrap.empty();
    let location = _.find(window.locations, { id: id });
    let html     = ``;
    _.each(location.brokers, (broker) => {
        html += brokerTemplate(broker);
    });
    $brokersWrap.html(html);
    $('.js-brokers-location').text(location.title);
}

function brokerTemplate(broker) {
    return `<div class="brokers__wrap__grid__item">
                <p class="brokers__wrap__grid__item__title"><strong>${broker.title}</strong></p>
                <p class="brokers__wrap__grid__item__jobtitle">${broker.job_title.toUpperCase()}</p>
                <div class="divide"></div>
                ${(broker.phone) ? `<p class="brokers__wrap__grid__item__phone"><a href="tel:${broker.phone}">P ${broker.phone}</a></p>` : ``}
                ${(broker.email) ? `<p class="email"><a href="mailto:${broker.email}"><span class="colour--green">> </span> email ${broker.title}</a></p>` : ``}
            </div>`;
}

$body.on('click', '.js-remove-location-filter', function (e) {
    e.preventDefault();
    $('.brokers').hide();
    $('html, body').animate({
        scrollTop: $('.maps').offset().top
    }, 800);
    let bounds = new google.maps.LatLngBounds();
    _.each(markers, (marker) => {
        bounds.extend(new google.maps.LatLng(marker.details.lat, marker.details.lng));
    });
    map.fitBounds(bounds);
});

$body.on('change', '.js-people', function (e) {
    e.preventDefault();
    if ($(this).val() !== null) {

        $brokersWrap.empty();
        let html         = ``;
        let actualPerson = parseInt($('.js-people').val());
        _.each(people, (person) => {
            if (person.id === parseInt($('.js-people').val())) {
                html += brokerTemplate(person);
                $('.js-brokers-location').text(person.branch);
                window.dataLayer.push({
                    'event'         : 'ga_event',
                    'event_category': 'Find Broker Region',
                    'event_action'  : `${person.region}, ${person.branch}`,
                    'event_label'   : person.title
                });
            }
        });
        let actualBranch;
        _.each(brokers, (broker) => {
            _.each(broker.brokers, (person) => {
                if (actualPerson === person.id) {
                    actualBranch = broker.id;
                }
            });
        });
        let bounds = new google.maps.LatLngBounds();
        _.each(markers, (marker) => {
            if (actualBranch === marker.details.id) {
                bounds.extend(new google.maps.LatLng(marker.details.lat, marker.details.lng));
                infoBox(marker.details);
            }
        });
        map.fitBounds(bounds);

        setTimeout(() => map.setZoom(map.getZoom() - 4), 10);
        $brokersWrap.html(html);
        $('.brokers').show();
        $('html, body').animate({ scrollTop: $('.brokers__wrap').offset().top }, 800);

    }
});
