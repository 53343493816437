/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import _ from 'lodash';
import autosize from 'autosize';
const select2 = require('select2');

/* Imports ---------------------------------------------------------*/

// import './breakpoints';
// import './jsAspect';
import './slider';
import './modals';
import './contactForm';
// import './filters';
import './scrollAnimations';
import './accordions';
// import Embed from './embedVideo';

import './crombie';

import Parallax from './parallax';
import Equalizer from './equalizer';
import Breakpoint from './breakpoints';

document.querySelectorAll('[data-parallax]').forEach((group) => new Parallax(group));
document.querySelectorAll('[data-equalize]').forEach((group) => new Equalizer(group));
document.querySelectorAll('[data-breakpoint]').forEach((group) => new Breakpoint(group));

// ==================================================================
// Helpers

function random(min = 0, max = 1) {
  return Math.round(Math.random() * (max - min) + min);
}

// function lastInRow($element) {
// 	var $siblings = $element.parent().find('>*');
// 	let offsetTop = $element.offset().top;
// 	var $item = $element;

// 	$siblings.slice($item.index()).each(function () {
//         if ($(this).offset().top > offsetTop) {
//             return false;
//         } else {
//             $item = $(this);
//         }
//     });
 
//     return $item;
// }


// ==================================================================
// Variables

let $body = $('body');
let currentURL = location.protocol+'//'+location.host+location.pathname;

// ==================================================================
// Select2

select2($);

$('form select').select2({width:'100%', minimumResultsForSearch: -1});

// ==================================================================
// Autosize

autosize($('textarea'));

// ==================================================================
// Search Validation -- Needs to have a value before submit

$('.searchModal form, .searchResults form').on('submit', function(e){
	let $this = $(this);
	let $input = $this.find('input[type="text"]');

	if ($input.val() == '') {
		e.preventDefault();
	}
});


// 